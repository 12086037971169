<template>
  <v-card class="Card" color="#f2f2f2">
    <v-row>
      <v-col md="8" cols="12" class="py-0">
        <apexchart
          type="line"
          height="250"
          ref="chart"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
      <v-col md="4" cols="12" class="py-0 d-flex justify-end align-center">
        <div
          v-text="ticker_card.price"
          class="mr-3"
          style="font-size: 8em"
        ></div>

        <div style="width: 33%">
          <span
            v-if="trend == 1"
            class="green--text"
            style="font-size: 5em; opacity: 0.5"
          >
            ▲
          </span>
          <span
            v-if="trend == -1"
            class="red--text"
            style="font-size: 5em; opacity: 0.5"
          >
            ▼
          </span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "CardComponent",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    ticker: {
      type: String,
    },
    clear: {
      type: Boolean,
    },
  },

  data: () => ({
    trend: undefined,
    source: undefined,
    ticker_card: {},
    new_data: [],
    series: [
      {
        name: "",
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        id: "realtime",
        type: "line",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "stepline",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        labels: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
    },
  }),

  computed: {
    ...mapState(["backend_url"]),
  },

  methods: {
    add_event(event) {
      // обновляем цену в реальном времени
      let data = JSON.parse(event.data);
      this.trend =
        this.ticker_card.price > data.value
          ? -1
          : this.ticker_card.price < data.value
          ? 1
          : 0;
      this.new_data.push(data.value);
      this.ticker_card.price = data.value;
      if (this.new_data.length > 50) this.new_data = this.new_data.slice(-5);
      this.$refs.chart.updateSeries([{ data: this.new_data }]);
    },

    async get_ticker_card() {
      // получаем карточки интсрументов
      return axios
        .post(this.backend_url + "ticker_card", {
          ticker_name: this.ticker,
        })
        .then((res) => res.data)
        .then((json) => {
          this.ticker_card = json.response;
        });
    },
  },

  watch: {
    clear() {
      if (this.clear) {
        this.ticker_card.price = 0;
        this.new_data = [];
      }
    },

    ticker() {
      if (this.ticker) {
        this.get_ticker_card();
        if (this.source) this.source.close();

        this.source = new EventSource(
          this.backend_url + "stream?channel=" + this.ticker
        );
        this.source.removeEventListener("price", this.add_event, false);
        this.source.addEventListener("price", this.add_event, false);
      }
    },

    ticker_card() {
      this.new_data = this.ticker_card.history;
      this.$refs.chart.updateSeries([{ data: this.new_data }]);
    },
  },

  created: function () {},
};
</script>
