<template>
  <v-app id="App">
    <v-main>
      <v-container>
        <v-card flat width="1000" class="pt-16 mx-auto">
          <v-select
            v-model="selected_ticker"
            :items="tickers_names"
            :menu-props="{ maxHeight: '800' }"
            label="Выберите инструмент"
            persistent-hint
          ></v-select>
          <v-btn style="position: absolute; right: 0" @click="clear_price()"
            >Рестарт цен</v-btn
          >

          <Card :ticker="selected_ticker" :clear="clear" class="mt-16" />
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Card from "./components/Card";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    Card,
  },

  data: () => ({
    tickers_names: [],
    selected_ticker: undefined,
    clear: false,
  }),

  computed: {
    ...mapState(["backend_url"]),
  },

  watch: {
    clear() {
      if (this.clear) setTimeout(() => (this.clear = false), 100);
    },
  },

  methods: {
    async get_tickers() {
      // получаем названия инструментов
      return axios
        .post(this.backend_url + "tickers_names", {})
        .then((res) => res.data)
        .then((json) => (this.tickers_names = json.response));
    },

    async clear_price() {
      //  обнуляем цены
      return axios
        .post(this.backend_url + "clear", {})
        .then((res) => res.data)
        .then((json) => {
          if (json.response) this.clear = true;
        });
    },
  },
  created: function () {
    this.get_tickers();
  },
};
</script>
